import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Impressum.module.css';

function About() {
  return (
    <div className={styles.container}>
    <header className={styles.header}>
        Impressum
    </header>
      <address>
        Uncropped Media<br />
        Teplitzer Str. 11 <br />
        01773 Altenberg <br />
      </address>

      <p>
        E-mail: post@uncropped.media
      </p>

      <p>
        Redaktionelle Verantwortung:<br />
        Uncropped Media<br />
        Teplitzer Str. 11<br />
        01773 Altenberg
      </p>

      <p>
        Haftungshinweis:<br />
        Wir übernehmen keinerlei Verantwortung oder Haftung für die Angaben auf dieser Webseite. Unser Ziel ist es, aktuelle und genaue Informationen bereitzustellen. Allerdings kann nicht garantiert werden, dass die auf dieser Webseite verfügbaren Angaben tatsächlich aktuell, umfassend, komplett oder genau sind.
        Bei den bereitgestellten Informationen handelt es sich um solche allgemeiner Art, die nicht auf die besonderen Bedürfnisse bestimmter Personen oder Unternehmen abgestimmt sind. Insbesondere soll durch sie keine Beratung erfolgen.
        Sofern von dieser Webseite auf andere Webseiten verwiesen wird, können wir den Inhalt nicht beeinflussen und für diesen auch keine Verantwortung übernehmen.
      </p>

      <p>
      <Link to="/">Home</Link>
      </p>
    </div>
  );
}

export default About;
