import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Home.module.css';

function Home() {
  return (
    <div className={styles.wrapper}>
        <nav>
        <svg
                xmlns="http://www.w3.org/2000/svg"
                width="680"
                height="200"
                version="1.1"
                viewBox="0 0 700 170"
                >
                <g
                    fill="none"
                    stroke="#00e4ff"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    fontStretch="normal"
                    fontStyle="normal"
                    fontVariant="normal"
                    fontWeight="normal"
                    wordSpacing="0"
                >
                    <g
                    strokeWidth="2.5"
                    aria-label="UNCROPPED"
                    fontFamily="Latin Modern Sans"
                    fontSize="59.167"
                    transform="matrix(1.26555 0 0 .79797 -1.789 0)"
                    style={{ lineHeight: "1.25" }}
                    letterSpacing="20.009"
                    >
                    <path pathLength="1"
                        d="M54.155 66.434V39.158H49.54v27.335c0 7.81-4.97 11.715-9.94 11.715-4.852 0-9.703-3.728-9.703-11.715V39.158H24.63v27.276c0 8.638 6.922 15.087 14.91 15.087 7.928 0 14.614-6.508 14.614-15.087z"
                        style={{
                        InkscapeFontSpecification: "'Latin Modern Sans'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        }}
                    ></path>
                    <path pathLength="1"
                        d="M115.936 80.22V39.157h-4.615v36.86h-.06l-3.964-8.105-14.496-28.755h-7.336v41.061h4.615V43.36h.059l3.964 8.105L108.6 80.22z"
                        style={{
                        InkscapeFontSpecification: "'Latin Modern Sans'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        }}
                    ></path>
                    <path pathLength="1"
                        d="M176.414 77.557l-.355-4.083c-1.715 1.184-3.431 2.308-5.443 2.9-1.893.65-3.964.71-5.976.71-3.846 0-7.336-2.071-9.762-5.089-2.722-3.49-4.024-7.869-4.024-12.307 0-4.437 1.302-8.875 4.024-12.365 2.426-3.018 5.916-5.089 9.762-5.089 1.775 0 3.61.178 5.325.71 1.775.533 3.432 1.42 4.97 2.485l.829-4.851c-1.775-.71-3.61-1.302-5.503-1.657-1.834-.355-3.727-.473-5.62-.473-5.207 0-10 2.366-13.49 6.212-3.67 4.142-5.562 9.526-5.562 15.028 0 5.503 1.893 10.887 5.561 14.97 3.491 3.846 8.284 6.212 13.49 6.212 2.071 0 4.142-.059 6.154-.65 2.011-.533 3.845-1.598 5.62-2.663z"
                        style={{
                        InkscapeFontSpecification: "'Latin Modern Sans'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        }}
                    ></path>
                    <path pathLength="1"
                        d="M236.006 80.22L224.29 60.93c6.094-1.775 10.058-5.976 10.058-10.768 0-5.858-6.449-11.005-14.91-11.005h-14.26v41.061h5.09V61.463h9.348L230.74 80.22zm-6.45-30.057c0 4.2-3.786 7.691-10.768 7.691h-8.52V42.471h8.52c6.864 0 10.769 3.49 10.769 7.692z"
                        style={{
                        InkscapeFontSpecification: "'Latin Modern Sans'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        }}
                    ></path>
                    <path pathLength="1"
                        d="M297.905 59.807c0-12.425-8.58-21.951-18.401-21.951-10.059 0-18.46 9.644-18.46 21.95 0 12.544 8.756 21.715 18.4 21.715 9.882 0 18.46-9.29 18.46-21.714zm-5.266-.888c0 11.538-6.39 18.756-13.135 18.756-6.982 0-13.194-7.396-13.194-18.756 0-10.827 6.508-17.276 13.135-17.276 6.863 0 13.194 6.685 13.194 17.276z"
                        style={{
                        InkscapeFontSpecification: "'Latin Modern Sans'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        }}
                    ></path>
                    <path pathLength="1"
                        d="M355.72 51.11c0-6.332-5.857-11.952-14.022-11.952h-14.732v41.061h5.266V63.12h9.88c7.337 0 13.609-5.384 13.609-12.01zm-4.792 0c0 4.673-3.727 8.578-10.531 8.578h-8.343V42.471h8.343c6.508 0 10.531 3.61 10.531 8.638z"
                        style={{
                        InkscapeFontSpecification: "'Latin Modern Sans'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        }}
                    ></path>
                    <path pathLength="1"
                        d="M413.537 51.11c0-6.332-5.857-11.952-14.022-11.952h-14.733v41.061h5.266V63.12h9.88c7.338 0 13.61-5.384 13.61-12.01zm-4.792 0c0 4.673-3.728 8.578-10.532 8.578h-8.342V42.471h8.342c6.508 0 10.532 3.61 10.532 8.638z"
                        style={{
                        InkscapeFontSpecification: "'Latin Modern Sans'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        }}
                    ></path>
                    <path pathLength="1"
                        d="M469.697 80.22v-4.201h-7.455c-.71 0-1.42.059-2.13.059h-12.366V60.753h19.525v-3.609h-19.525V43.122h8.165c.71 0 1.42.059 2.13.059h10.828v-3.846H442.48V80.22z"
                        style={{
                        InkscapeFontSpecification: "'Latin Modern Sans'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        }}
                    ></path>
                    <path pathLength="1"
                        d="M531.596 60.043c0-11.478-7.988-20.885-18.165-20.885H497.93v41.061h15.56c10.059 0 18.106-8.993 18.106-20.176zm-5.089 0c0 10.059-6.153 16.863-14.436 16.863h-9.053V42.471h9.053c8.283 0 14.436 7.16 14.436 17.572z"
                        style={{
                        InkscapeFontSpecification: "'Latin Modern Sans'",
                        fontVariantLigatures: "normal",
                        fontVariantCaps: "normal",
                        fontVariantNumeric: "normal",
                        fontVariantEastAsian: "normal",
                        }}
                    ></path>
                    </g>
                    <g
                    strokeWidth="5"
                    aria-label="MEDIA"
                    fontFamily="Nasa"
                    fontSize="194.118"
                    transform="scale(1.15203 .86804)"
                    style={{ lineHeight: "1.25" }}
                    letterSpacing="3.58"
                    >
                    <path pathLength="1"
                        d="M204.851 207.933h-26.35q-3.127-12.132-6.16-24.265-7.394-28.15-12.986-44.833-7.962-24.075-11.658-23.6-2.56.568-3.507 1.99-.853 1.327-.853 3.791v63.6q0 10.995-9.1 18.199-8.53 6.73-20.378 6.73-10.237 0-18.389-5.403-9.099-6.066-12.89-17.346-.758-2.275-6.92-24.644-5.592-20.283-9.762-31.752-5.782-15.64-9.384-15.166-2.37.569-3.317 1.99-.948 1.328-.948 3.792v86.917H26.657v-92.888q0-12.701 9.573-19.336 8.247-5.687 21.137-5.687 17.251 0 27.393 17.345 6.824 11.564 12.985 37.44 6.825 28.435 9.953 35.26.947 2.18 2.654 3.412 1.8 1.232 3.696 1.232 1.706 0 2.654-1.137 1.043-1.138 1.043-3.507v-65.022q0-12.701 9.573-19.336 8.246-5.687 21.137-5.687 10.52 0 18.578 7.393 8.625 7.962 13.08 23.601z"
                        style={{ InkscapeFontSpecification: "Nasa" }}
                    ></path>
                    <path pathLength="1"
                        d="M305.017 207.933h-65.875q-14.787 0-23.412-11.09-7.772-9.952-7.772-24.454 0-13.08 6.54-22.653-6.54-9.574-6.54-22.464 0-14.218 7.772-24.17 8.625-10.995 23.412-10.995h65.875v24.454h-61.61q-4.17 0-6.635 3.223-2.37 3.128-2.37 7.393 0 4.17 2.275 7.109 2.37 2.938 6.73 2.938h61.61v25.023H239.9q-2.56 1.138-4.076 3.981-1.422 2.844-1.422 6.256 0 4.265 2.275 7.393 2.37 3.128 6.73 3.128h61.61z"
                        style={{ InkscapeFontSpecification: "Nasa" }}
                    ></path>
                    <path pathLength="1"
                        d="M375.799 207.933h-56.587V92.107H375.8q20.094 0 32.037 18.388 10.805 16.587 10.805 40.473 0 23.317-10.616 39.24-11.848 17.725-32.226 17.725zm-4.266-25.118q9.1 0 14.597-10.615 5.024-9.668 5.024-23.317 0-13.27-4.834-22.275-5.403-10.047-14.787-10.047h-26.729v66.254z"
                        style={{ InkscapeFontSpecification: "Nasa" }}
                    ></path>
                    <path pathLength="1"
                        d="M456.533 207.933H430.94V92.107h25.592zm-25.592 0z"
                        style={{ InkscapeFontSpecification: "Nasa" }}
                    ></path>
                    <path pathLength="1"
                        d="M585.038 207.933h-27.392l-30.236-89.097q-.285-1.422-1.99-1.422-1.612 0-2.37 1.422l-29.573 89.097h-27.772q2.37-8.246 10.142-32.795 7.867-24.55 15.26-46.444 8.246-24.36 10.047-27.109 3.413-4.834 9.953-7.393 6.54-2.559 14.217-2.559 7.678 0 14.123 2.56 6.54 2.558 9.953 7.392 2.085 2.844 10.236 26.919 5.024 14.786 14.976 46.444 6.066 19.241 10.426 32.985z"
                        style={{ InkscapeFontSpecification: "Nasa" }}
                    ></path>
                    </g>
                </g>
            </svg>
            
            <ul>
                <li>
                    <Link to="/" className={styles.active}>Home</Link>
                </li>
                <li>
                    <Link to="/news">Neuigkeiten</Link>
                </li>
                <li>
                    <Link to="/gallery">Projekte</Link>
                </li>
                <li>
                    <Link to="/contact">Kontakt</Link>
                </li>
            </ul>
        </nav>
        <main>

            <article>
            <section className={styles.paragraph}>
                <h1>Das sind wir!</h1>
            </section>
                <section className={styles.paragraph}>
                    <img src="./portrait.svg" alt="portrait icon" />
                    <p>
                        <h1>Portraitfilme</h1>
                        Ihre Firma, Ihr Verein von der besten Seite in Bild und Ton. Mehr als nur ein Imagefilm!
                    </p>
                </section>

                <section className={styles.paragraph}>
                    <img src="./nature.svg" alt="portrait icon" />
                    <p>
                        <h1>Naturdokus</h1>
                        Die Natur in ihrer Schönheit und Ursprünglichkeit. Festgehalten für die Zukunft.
                    </p>
                </section>

                <section className={styles.paragraph}>
                    <img src="./music.svg" alt="portrait icon" />
                    <p>
                        <h1>Musikvideos</h1>
                        Ihr musikalisches Projekt in Szene gesetzt
                    </p>
                </section>
                <section className={styles.paragraph}>
                    <Link to="/news" className={styles.moreBtn}>Mehr Erfahren</Link>
                </section>
            </article>

            <section className={styles.contactBtnGroup}>
                <Link to="/contact">
                    <img src="./email.svg" alt="contact icon" />
                    <p>Kontakt</p>
                </Link>
                <a href="https://www.youtube.com/@ucm-naturundlandschaft/videos">
                    <img src="./youtube-logo.svg" alt="youtube icon" />
                    <p>YouTube</p>
                </a>
                <a href="https://www.instagram.com/uncropped.media/">
                    <img src="./instagram-logo.svg" alt="instagram icon" />
                    <p>Instagram</p>
                </a>
            </section>
        <Link to="/impressum" className={styles.imprBtn}>Impressum</Link>
        </main>
    </div>
  );
}

export default Home;
