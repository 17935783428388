import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Article.module.css'; // Import CSS module

const Article = ({ thumbnail, text, link }) => {
  return (
    <div className={styles.article}>
      <img src={thumbnail} alt="Article Thumbnail" className={styles.thumbnail} />
      <div className={styles.textContainer}>
        <p className={styles.articleText}>{text}</p>
        <a href={link} target="_blank" rel="noopener noreferrer" className={styles.moreButton}>
          Mehr
        </a>
      </div>
    </div>
  );
};

export default Article;
