import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Card.module.css'; // Import the CSS module

const Card = ({ image, text, link }) => {
  return (
    <div className={styles.card}>
      <img src={image} alt="card image" className={styles.cardImage} />
      <p className={styles.cardText}>{text}</p>
      <Link to={link} className={styles.cardButton}>Ansehen</Link>
    </div>
  );
};

export default Card;
