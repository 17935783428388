import React from 'react';
import styles from './Contact.module.css';

function Contact() {
  const emailPrefix = 'post';
  const emailDomain = 'uncropped.media';
  const mailtoLink = `mailto:${emailPrefix}@${emailDomain}`;

  return (
    <div className={styles.container}>
      <section className={styles.imageSection}>
      </section>
      <section className={styles.infoSection}>
        <div className={styles.infoContent}>
          <h2>Kontakt</h2>
          <p>
            Haben Sie Fragen oder benötigen Sie Unterstützung bei Ihrem Projekt?<br />
            Planen Sie ein eigenes Filmprojekt und möchten es mit uns besprechen?<br />
            Zögern Sie nicht, uns zu kontaktieren – wir freuen uns, von Ihnen zu hören!
          </p>
          <p>
            <strong>Schreiben Sie uns!</strong><br />
            <a href={mailtoLink}>{emailPrefix}@{emailDomain}</a>
          </p>
        </div>
      </section>
    </div>
  );
}

export default Contact;
